<template>
  <BaseCard
    width="half"
    class="error-container p-20"
  >
    <NuxtPwaManifest />
    <div class="text-center">
      <h1 v-if="error.statusCode === 404">
        Page not found
      </h1>
      <h1 v-else>
        An error occurred
      </h1>
      <h4 class="fc-secondary">
        {{ error.message }}
      </h4>
      <NuxtLink to="/">
        Home page
      </NuxtLink>
    </div>
  </BaseCard>
</template>

<script>
export default {
  layout: 'login',
  props: ['error'],
}
</script>

<style>
.error-container {
  z-index: 1;
}
</style>
